import { Heading } from "@chakra-ui/react"
import { WindowLocation } from "@reach/router"
import React from "react"
import Layout from "../components/layout/layout"

export default function Aanmelden({ location }: { location: WindowLocation }) {
  return (
    <Layout title="Partners" location={location}>
      <div className="page">
        <Heading>Partners</Heading>

        <br />
        <p>Op deze pagina verzamelen we leuke links en partners.&nbsp;</p>

        <p>
          <a href="https://www.google.nl/maps">https://www.google.nl/maps</a>
          &nbsp;maak mooie fietsroutes en ga met de fiets er op uit naar eten,
          drinken, plantjes en cadeautjes.
        </p>
           <p>
          <a href="https://www.wovar.nl/klein-tuingereedschap/">
            Tuinschepjes, harkjes en meer
          </a>
          &nbsp;Ideale gereedschappen om te werken in de moestuin 
        </p>
        <p>
          <a href="https://www.ah.nl/allerhande/recepten/R-L1383828810698/lekker-biologisch">
            https://www.ah.nl/allerhande/recepten/R-L1383828810698/lekker-biologisch
          </a>
          &nbsp;biologische recepten
        </p>
        <p>
          <a href="hhttps://cookinglife.nl/keukengerei/">
            Cookinglife keukengerei
          </a>
          &nbsp;Keukengerei voor het verwerken van al je groentes en fruit uit eigen tuin. Van appelschilmachine tot koksmes. 
        </p>
        <p>
          <a href="https://www.fairsy.co.uk/">
            https://www.fairsy.nl/facebook/
          </a>
          &nbsp;Volg jouw regio met onze speciale facebook provincie accounts
        </p>
        <p>
          <a href="https://www.voedingscentrum.nl/">
            https://www.voedingscentrum.nl/
          </a>
          &nbsp;alles over voeding
        </p>
        <p>
          <a href="https://www.skal.nl/">https://www.skal.nl/</a>
          &nbsp;certificering van biologische groenten en fruit
        </p>
        <p>
          <a href=" https://blog.fairsy.nl/"> https://blog.fairsy.nl//</a>
          &nbsp;Fairsy blog met tips voor zelf een stalletje langs de weg
          beginnen.
        </p>

        <p>
          <a href="https://gadero.nl/houten-moestuinbak/">
            https://gadero.nl/houten-moestuinbak/
          </a>
          &nbsp;zelf moestuinbak van hout maken in uw tuin
        </p>
        <p>
          <a href="https://www.fairsy.co.uk/">https://www.fairsy.co.uk/</a>
          &nbsp;Fairsy Roadside stalls Engeland (leuk tijdens je vakantie)
        </p>
        <p>
          <a href="https://kwitantie.app">https://kwitantie.app</a>
          &nbsp;Grotere bedragen? Stel een kwitantie op als particulier.
        </p>
        <p>
          Heeft u ook een hoogwaardige blog, webshop of website in de sector
          eten/drinken/tuin? Stuur dan een email naar hi@fairsy.nl voor een
          verzoek tot samenwerking voor blogruils en linkruils.&nbsp;
        </p>
      </div>
    </Layout>
  )
}
